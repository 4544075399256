// Import the necessary GSAP libraries
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default function Animations(el) {
  // Register ScrollTrigger plugin
  gsap.registerPlugin(ScrollTrigger);
  // animation goes here
  // Set up the target element
  const elements = document.querySelectorAll(".target-element");
  const textElements = document.querySelectorAll(".letter-animation");

  // Create the ScrollTrigger animation
  elements.forEach((element) => {
    gsap.to(element, {
      scrollTrigger: {
        trigger: element,
        start: "top 100%", // Adjust the start position as per your requirement
        end: "top 20%", // Adjust the end position as per your requirement
        scrub: true, // Enables smooth scrubbing effect
        markers: false, // Set to true to visualize the ScrollTrigger markers
      },
      y: -50, // Adjust the desired y position value
    });
  });

  textElements.forEach((textElement) => {
    const paragraphs = textElement.querySelectorAll("p");

    const tl = gsap.timeline({ delay: 1.3 });

    paragraphs.forEach((paragraph) => {
      const letters = paragraph.textContent.split("");
      const wrappedLetters = letters.map((letter) => {
        return `<span class="letter">${letter}</span>`;
      });
      paragraph.innerHTML = wrappedLetters.join("");

      const letterSpans = paragraph.querySelectorAll(".letter");
      gsap.set(letterSpans, { opacity: 0 });

      tl.fromTo(
        letterSpans,
        { opacity: 0 },
        { opacity: 1, duration: 0.5, stagger: 0.03 }
      );
    });
  });
}
