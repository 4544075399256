import "core-js/stable";
import "regenerator-runtime/runtime";
import Menu from "./js/Menu";
import "./app.scss";
// import Gallery from "./js/Gallery";
import More from "./js/More";
import Overview from "./js/Overview";
import Form from "./js/Form";
import Headroom from "headroom.js";
import Animations from "./js/Animations";
import Slider from "./js/Slider";
import Popup from "./js/Popup";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap/dist/gsap";

import lazySizes from "lazysizes";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
import CarForm from "./js/CarForm";

Animations();
runActions();

function action(attr, func) {
  if (attr.indexOf(".") !== -1 || attr.indexOf("#") !== -1) {
    document.querySelectorAll(attr).forEach((el) => func(el));
  } else {
    document
      .querySelectorAll(`[data-action="${attr}"]`)
      .forEach((el) => func(el));
  }
}

function runActions() {
  action("CarForm", CarForm);
  action("Slider", Slider);
  // action("Gallery", Gallery);
  action("Form", Form);
  Menu(document.querySelectorAll(".mob-nav-btn"));
  Overview(document.querySelectorAll(".more-overview-btn"));
  More(document.querySelectorAll(".more-btn"));
  const headroomElement = document.querySelector("#main-header");
  const headroom = new Headroom(headroomElement, {
    offset: 100,
  });
  headroom.init();
}
Popup(document.querySelectorAll(".popup-btn"));
gsap.registerPlugin(ScrollTrigger);

// Only used in dev
if (module.hot) {
  module.hot.accept();
}

var headings = document.querySelectorAll(".accordion .left .accord-heading");
var contents = document.querySelectorAll(".accordion .right .accord-content");

headings.forEach(function (heading, index) {
  heading.addEventListener("click", function () {
    // Hide all contents
    contents.forEach(function (content) {
      content.classList.remove("active");
    });

    // Show the clicked content
    contents[index].classList.add("active");

    // Toggle active class on headings
    headings.forEach(function (h) {
      h.classList.remove("active");
    });
    heading.classList.add("active");
  });
});
