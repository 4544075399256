import { gsap } from "gsap";

export default function Popup(els) {
  let isPopupOpen = false;

  els.forEach((el) =>
    el.addEventListener("click", () => {
      let popup;

      if (el.dataset.target) {
        popup = document.querySelector(el.dataset.target);
      } else {
        popup = document.querySelector(".popup:not(.hidden)");
      }

      document.body.classList.toggle("overflow-hidden");
      if (!isPopupOpen) {
        isPopupOpen = true;
        popup.classList.remove("hidden");
        gsap.fromTo(popup, { opacity: 0 }, { opacity: 1, duration: 0.2 });
      } else {
        isPopupOpen = false;
        gsap.fromTo(
          popup,
          { opacity: 1 },
          {
            opacity: 0,
            duration: 0.2,
            onComplete: () => popup.classList.add("hidden"),
          }
        );
      }
    })
  );
}
